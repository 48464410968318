<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Alert :message="requestError" type="alert-danger" v-if="requestError" />

    <div class="row">
      <div class="col-md-12 content_margin_bottom">
        <div class="row">
          <div class="col-md-12 mb-4">
            <h4 class="card-title my-3 float-right">
              <router-link
                :to="`/entity/${entityId}/course/add`"
                class="btn btn-success btn-sm mr-3"
              >
                New Course
              </router-link>
              <router-link
                :to="`/entity/${entityId}/courses`"
                class="btn btn-success btn-sm mr-3"
              >
                Courses
              </router-link>
            </h4>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="enrolledUsers"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sorrt-desc.sync="sortDesc"
                :fit-by.sync="sortBy"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                hover
              >
                <template #cell(name)="data">
                  <b class="text-info">{{ data.value.first }}</b
                  >, <b>{{ data.value.last }}</b>
                </template>
                <template v-slot:cell(action)="row">
                  <div class="dropdown">
                    <a
                      href="#"
                      data-toggle="dropdown"
                      data-caret="false"
                      class="text-muted"
                      ><b-icon-three-dots></b-icon-three-dots
                    ></a>
                    <div class="dropdown-menu dropdown-menu-right p-3">
                      <router-link
                        :to="`/entity/${entityId}/category/${row.item.id}`"
                        class="btn text-primary"
                      >
                        Details
                      </router-link>
                      <button
                        @click="getKeyAndId(row.index, row.item.id)"
                        class="btn text-danger"
                        data-toggle="modal"
                        data-target="#deleteModal"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layout/index.vue";
import PageHeader from "@/components/page-header";
import Alert from "@/components/Alert.vue";
import appConfig from "@/app.config";
import store from "@/state/store";

export default {
  page: {
    title: "Enrolled Users",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Alert,
    PageHeader,
    Layout,
  },
  data() {
    return {
      // data table meta
      entityId: this.$route.params.id,
      courseId: this.$route.params.courseId,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "full_name", sortable: true, label: "Full Name" },
        { key: "username", sortable: true, label: "Email" },
        { key: "phone", sortable: true, label: "Contact" },
        // { key: "Action" },
      ],
      //end of table meta
      loading: false,
      id: null,
      requestError: null,
      success: null,
      error: null,
      key: null,
      title: "Enrolled Users",
      items: [
        {
          text: "Entity Admin",
        },
        {
          text: "Enrolled Users",
          active: true,
        },
      ],
      enrolledUsers: [],
    };
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    fetchData() {
      store
        .dispatch("course/fetchEnrollUsers", this.courseId)
        .then((res) => {
          console.log(res.data.individuals);
          const data = res.data.individuals;

          const users = data.map((user) => {
            return {
              full_name: `${user.first_name} ${user.other_names} ${user.last_name}`,
              username: user.email,
              phone: user.phone,
            };
          });
          this.enrolledUsers = users;
          console.log(users);

          this.totalRows = this.enrolledUsers.length;
        })
        .catch((err) => {
          if (err?.response?.data === undefined)
            this.requestError = "An error occures";
        });
    },
  },
  computed: {
    // enrolledUsers() {
    //   return store.getters["course/allenrollusers"];
    // },
    rows() {
      return this.enrolledUsers && this.enrolledUsers.length;
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {},
};
</script>
<style scoped>
th {
  text-transform: uppercase;
}
</style>
